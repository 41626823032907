import { getLocalizedUrl } from "../common/localized-link/localized-link"
import { useLocale } from "../common/use-locale/use-locale"
import * as React from "react"
import { navigate } from "gatsby"
import { useEffect } from "react"
import { CommonLayout } from "../common/common-layout/common-layout"

export default function Career(props) {
  return (
    <div style={{ display: "none" }}>
      <CommonLayout {...props}>
        <CareerRedirect />
      </CommonLayout>
    </div>
  )
}

export const CareerRedirect = () => {
  const { locale } = useLocale()

  useEffect(() => {
    navigate(location.origin + getLocalizedUrl("/career/benefits", locale))
  })
  return <></>
}
